export enum FabricComponent {
  Main = "Main Canopy",
  MainAlternating = "Canopy Fabric, Alternating Panel",
  MainTrim = "Main Canopy Trim",
  MainTrimInner = "Main Canopy Inner Trim",
  TopVent = "Top Vent",
  MiddleVent = "Middle Vent",
  MiddleAlternating = "Middle Vent, Alternating Panel",
  Valance = "Valance",
  ValanceAlternating = "Valance, Alternating Panel",
  VentTrim = "Vent Trim",
  Binding = "Binding",
  Pockets = "Pockets",
  FringeFirst = "First Fringe",
  FringeSecond = "Second Fringe",
  FringeThird = "Third Fringe",
}
