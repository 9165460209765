



















































































































































































































































































































































































































































































































































































































































































































































































































































import { Component } from "vue-property-decorator";
import Swatch from "@/components/designer/fabric/Swatch.vue";
import ValanceGroup from "@/components/designer/sidebar/canopy/ValanceGroup.vue";
import FabricSelector from "@/components/designer/fabric/picker/FabricSelector.vue";
import CanopyLayouts from "./CanopyLayouts";
import FlipValanacePanels from "@/components/designer/sidebar/canopy/FlipValancePanels.vue";

@Component({
  components: {
    Swatch,
    ValanceGroup,
    FabricSelector,
    FlipValanacePanels,
  },
})
export default class StandardLayout extends CanopyLayouts {
  
  mounted() {
    this.readStoreData();
  }

  protected readStoreData(): void {
    this.selectedMainCanopy = this.mainCanopy;
    this.selectedVentMiddle = this.ventMiddle;
    this.selectedRib = this.rib;
  }
}
